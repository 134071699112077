import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from 'react-helmet'
import { Link } from "gatsby"
import Footer from '../components/Footer'

// markup
const Privacy = (props) => {

    const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

    return (
        <main>
            <Helmet title="Privātuma politika" defer={false} />



            <div class="container content">

                <section class="section">

                    <div className="columns is-mobile">
                        <div className="column"><Link to="/"><Img fixed={data.logo.childImageSharp.fixed} alt="Heyday" /> </Link></div>
                        <div className="column is-narrow"><Link to="/" class="button is-rounded is-outline">Atpakaļ</Link></div>
                    </div>


                    <h1 class="title is-1">Privātuma politika</h1>

                    <p>Publicēta 2021. gada 18. februārī</p>

                    <p>Heyday pakalpojuma sniedzējs, SIA Unicorns Unlimited (turpmāk – "Heyday") ir apņēmies aizsargāt un ievērot Jūsu privātumu. Jūsu privātums un datu aizsardzība Heyday ir ļoti nozīmīgs. Lai nodrošinātu likumīgu, godprātīgu un pārredzamu personas datu apstrādi, turpmāk tiek sniegta informācija par Heyday veikto personas datu apstrādi. Vēršam uzmanību, ka norādītā informācija nav izsmeļoša un var tikt grozīta, precizēta, papildināta.</p>




                    <p class="title is-6">1. Pārzinis</p>
                    <p>Datu aizsardzības pārzinis ir SIA Unicorns Unlimited, juridiskā adrese Tālivalža iela 14-1, Rīga, LV-1006, reģistrācijas numurs 40203274222, tālrunis: +371 2208 3434, e-pasts: info@heyday.lv.</p>

                    <p class="title is-6">2. Saziņa par personas datu aizsardzības jautājumiem</p>

                    <p>Ja Jums ir radušies kādi jautājumi saistībā ar šo paziņojumu vai Jūsu personas datu apstrādi, tad Jūs varat ar mums sazinātiesm, rakstot uz e-pastu: info@heyday.lv.</p>

                    <p>2.1. saziņai ar Heyday datu aizsardzības speciālistu, variet, rakstot uz e-pastu info@heyday.lv ar atzīmi „Datu aizsardzības speciālistam".</p>

                    <p class="title is-6">3. Personas datu apstrādes vispārīgs raksturojums</p>

                    <p>Dati tiek apstrādāti, ievērojot konfidencialitātes prasības un rūpējoties par Heyday rīcībā esošo personu datu drošību. Heyday izmanto dažādus drošības pasākumus, lai nepieļautu nesankcionētu piekļuvi datiem, datu izpaušanu vai citu neatbilstošu personu datu izmantošanu. Heyday nodrošina pienācīgu šīs informācijas apstrādi, uzglabāšanu, datu integritāti ar atbilstošu drošības līmeni. Tiek izmantotas samērīgas un piemērotas fiziskās, tehniskās un administratīvās procedūras un līdzekļi, lai aizsargātu Heyday rīcībā esošos personas datus un to apstrādi. Veiktie drošības pasākumi tiek pastāvīgi pilnveidoti atbilstoši drošības prasībām.
</p>
                    <p>Heyday personas datus apstrādā nodarbinātie vai tieši pilnvarotās personas, kuriem tas nepieciešams darba pienākumu izpildei, valsts un pašvaldību iestādes tiesību aktos noteiktos gadījumos.
</p>
                    <p>Heyday apstrādā šādus datus: vārds, uzvārds, maksājuma adrese, piegādes adrese, kontaktinformācija, e-pasta adrese, telefona numurs, ierīces informācija, IP adrese un citi personu identificējoši dati atbilstoši tam, lai tiktu sasniegts attiecīgs datu apstrādes mērķis. Heyday nepieprasa un neievāc tādu informāciju par personu, kas nav nepieciešama konkrēta mērķa sasniegšanai.
</p>
                    <p>Heyday pastāvīgi veic datu apstrādes drošības novērtēšanu, lai arī turpmāk pēc iespējas novērstu nesankcionētu piekļuvi personu datiem, personu datu ļaunprātīgu izmantošanu, izmainīšanu, dzēšanu u.tml.
</p>


                    <p class="title is-6">4. Personu datu apstrādes nolūks un tiesiskais pamats</p>

                    <p>Heyday apstrādā personas datus normatīvajos aktos noteikto pienākumu izpildei, līgumos noteikto saistību izpildei, Heyday tiesisko interešu realizācijai, kā arī citiem iepriekš noteiktiem nolūkiem.
</p>
                    <p>Apstrādes pamats katrā konkrētajā gadījumā ir kāds no Eiropas Parlamenta un Padomes 2016. gada 27. aprīļa regulas (ES) 2016/679 par fizisku personu aizsardzību attiecībā uz personas datu apstrādi un šādu datu brīvu apriti un ar ko atceļ Direktīvu 95/46/EK (turpmāk - Vispārīgā datu aizsardzības regula) 6. panta punktiem. Galvenokārt Heyday personu datu apstrādi veic, pamatojoties uz Vispārīgās datu aizsardzības regulas 6.panta 1. punkta b), c) un e) apakšpunktiem -  apstrāde tiek veikta, lai izpildītu uz Heyday attiecināmu juridisku pienākumu, lai izpildītu uzdevumu, ko veic sabiedrības interesēs vai īstenojot Heyday likumīgi piešķirtās oficiālās pilnvaras.
</p>


                    <p class="title is-6">5. Personas datu saņēmēju kategorijas</p>

                    <p>Heyday personas datu apstrādes vajadzībām dati var tikt nodoti citām personām normatīvajos skatos noteiktajā kārtībā, piemēram, trešajām pusēm, kas var būt sadarbības partneri, pilnvarotās personas atbilstoši normatīvajiem aktiem, noslēgtiem līgumiem, kā arī kontrolējošām iestādēm, tiesībsargājošām iestādēm. Pirms datu nodošanas trešajām personām Heyday rūpīgi izvērtē, vai šādai datu nodošanai ir atbilstošs juridiskais pamats.
</p>


                    <p class="title is-6">6. Personas datu sniegšana ārpus Eiropas Savienības vai Eiropas Ekonomiskās zonas valstīm</p>

<p>Heyday  nenodod datus valstīm, kas atrodas ārpus Eiropas Savienības vai Eiropas Ekonomiskās zonas.
</p>


                    <p class="title is-6">7. Personas datu glabāšanas periods</p>

                    <p>Personas dati tiek glabāti tik ilgi, cik to glabāšana ir nepieciešama atbilstoši attiecīgiem personas datu apstrādes nolūkiem, kā arī saskaņā ar piemērojamo normatīvo aktu prasībām. Uzglabāšanas nolūki var mainīties. Piemēram, vispirms datu apstrāde notiek, lai pēc būtības izskatītu Jūsu iesniegumu. Kad lēmums pieņemts, dati tiek uzglabāti gan saskaņā ar spēkā esošajiem normatīvajiem aktiem par attiecīgu dokumentu uzglabāšanas nepieciešamību, kā arī, lai nodrošinātu Heyday leģitīmās intereses, kas varētu izrietēt no iespējamo domstarpību gadījumiem.
</p>


                    <p class="title is-6">8. Datu subjekta tiesības personas datu apstrādes procesā</p>

                    <p>Jums ir tiesības piekļūt saviem personas datiem un veikt to kontroli, datu apstrādes ierobežošanu, labošanu, dzēšanu, iebilst pret datu apstrādi, tāpēc, saņemot Jūsu pieprasījumu, Heyday uz to atbildēs normatīvajos aktos noteiktajā termiņā, un, ja būs iespējams, attiecīgi labos vai dzēsīs Jūsu personas datus.
</p>
                    <p>Ja datu apstrāde tiek veikta, pamatojoties uz datu subjekta sniegto piekrišanu, datu subjektam ir tiesības jebkurā brīdī atsaukt piekrišanu savu personīgo datu apstrādei, neskarot to datu apstrādi, kas notikusi līdz piekrišanas atsaukšanai. Heyday izvērtēs Jūsu prasības atbilstoši savām tiesiskajām interesēm. Ja personas dati vairs nav nepieciešami iepriekš noteiktajiem apstrādes mērķiem, tie tiks dzēsti.
</p>
                    <p>Jūs varat iegūt informāciju par mūsu rīcībā esošiem Jūsu personas datiem vai realizēt citas savas kā datu subjekta tiesības, iesniedzot iesniegumu elektroniski, nosūtot to uz e-pasta adresi info@heyday.lv. Iesniegumam jābūt parakstītam ar drošu elektronisko parakstu.
</p>
                    <p>Saņemot Jūsu iesniegumu, Heyday izvērtēs tā saturu un Jūsu identificēšanas iespējas un, ja nepieciešams, lūgs Jums iesniegt papildu informāciju, lai pēc iespējas pārliecinātos, ka dati tiks nosūtīti attiecīgajam datu subjektam un mazinātu risku ļaunprātīgai Jūsu datu izmantošanai.
</p>

                    <p class="title is-6">9. Tiesības iesniegt sūdzību</p>

                    <p>Ja Jums ir kādi jautājumi vai iebildumi saistībā ar Heyday veikto Jūsu personas datu apstrādi, aicinām vērsties pie Heyday, kā tas ir norādīts šī paziņojuma 2. punktā.
</p>
                    <p>Ja Jūs tomēr uzskatāt, ka neesam spējuši savstarpēji atrisināt radušos jautājumu un Heyday pārkāpj Jūsu tiesības uz Jūsu personas datu aizsardzību, Jums ir tiesības iesniegt sūdzību Datu valsts inspekcijai (http://www.dvi.gov.lv/lv/funkcijas/kontaktinformacija/) Blaumaņa ielā 11/13-11, Rīgā, LV-1011.
</p>




                    <div class="box">
                        <p class="title is-6">Kur meklēt palīdzību?</p>
                        <p class="">Droši raksti mums uz adresi <a href="mailto:info@heyday.lv">info@heyday.lv</a> vai <a href="call:+37122083434">zvani</a>.</p>
                    </div>




                    <div className="columns is-mobile mt-4">
                        <div className="column is-narrow"><Link to="/" class="button is-rounded is-primary">Atpakaļ</Link></div>
                        <div className="column"></div>
                    </div>




                </section>

            </div>




            <Footer />
        </main>
    )
}

export default Privacy
